export const WOMAN_STORY =  {
    id: '2',
    title: 'The precious mysterious woman',
    slug: 'woman',
    btn: '',
    footer: {
        closed: '',
        open: ''
    },
    abstract: {
        text: "ICARUS HRVATSKA is a non-profit association dedicated to the research of historical sources, the promotion of the open access to archives through new IT technologies and the development of cross-institutional and international cooperation in the field of cultural, scientific and professional activities. The Fine Arts Archives of the Croatian Academy of Sciences and Arts, founded in 1937, continuously and systematically collects, processes and preserves documentary and archival material related to the activities of Croatian artists from the second half of the 19th century to the present day. The documents used in this app belong to The Fine Arts Archives – Legacy of the sculptor Ivo Kerdić and relate to the public monument Zlatarevo Zlato (Goldsmith's Gold), located at the Kamenita vrata (Stone Gate) of the Zagreb Old City.",
        image: '',
        mode: 'single-page'
    },
    intro: [
        {
            title: 'Zagreb, Kamenita vrata, 1929',
            text: 'Vesna is a tourist guide from Zagreb, who wants to deepen her knowledge of Zagreb cultural heritage, so she often searches for new sources of information at different cultural institutions, such as libraries and archives.',
            image: '',
        },
        {
            text: "Tourist guide Vesna, while talking about the public sculpture The Goldsmith's Gold (Zlatarevo zlato) at the Stone Gate (Kamenita vrata) of Zagreb's Upper Town (Gornji grad), receives a question from a curious Italian tourist if it is known who was the model for the beautiful and gracious sculpture.",
            image: '',
        },
        {
            text: "Dora Krupićeva was one of the main characters from the known August Šenoa’s history novel The Goldsmith's Gold, placed in the 16th century Zagreb.",
            image: '',
        },
        {
            text: 'Since Vesna has a very limited knowledge on the history of the sculpture, she comes to the Fine Arts Archives of the Croatian Academy of Sciences and Arts, hoping for some answers.',
            image: '',
        },
    ],
    btnClueCompleted: '',
    clues:[
        {
            id: '20',
            title: 'The archives',
            data: {
                suggest: 'Vesna wonders which cultural heritage institution keeps documentation on Croatian visual artists and finds out there is such a place called The Fine Arts Archives of the Croatian Academy of Sciences and Arts in Zagreb. Great news: this is the right place! Let’s take a look if there are some documents about the sculpture. The right box have be the number 12.​',
                clueWidth: 60,
                clueHeight: 60,
                clueOffsetTop: 380,
                clueOffsetRight: 380,
                mode: 'horizontal',
                imgWidth: 930,
                imgHeight: 678
            },
            success: {
                text: 'The entire legacy of Ivo Kerdić, 18 boxes of archival material, is preserved at the Archives! With the help of archival finding aid Vesna locates the material in the box 12. There it is! Box number 12 contains a file named Zlatarevo zlato with many documents and sketches of the sculpture.',
                image: '',
            }
        },
        {
            id: '21',
            title: 'The sketch',
            data: {
                suggest: 'It’s possible that the author had a few solutions for the sculpture so let`s take a look. Very interestingly, some of the proposals were planned to be free-standing sculptures. Vesna is amazed that every proposed Dora looks very differently and that two free-standing sculptures hold just one object with both hands. But the prerequisite she is looking for is the sketch for a sculpture in a niche.​',
                mode: 'swipe',
                images: [
                    {
                        source : '',
                        isRight: false
                    },
                    {
                        source : '',
                        isRight: false
                    },
                    {
                        source : '',
                        isRight: true
                    }
                ]
            },
            success: {
                text: 'She finds the sketch of a sculpture positioned in a niche, that is the right one, even if it’s not shown in detail.',
                image: '',
            }
        },
        {
            id: '22',
            title: 'The model',
            data: {
                mode: 'swipe',
                images: [
                    {
                        source : '',
                        isRight: false
                    },
                    {
                        source : '',
                        isRight: true
                    },
                    {
                        source : '',
                        isRight: false
                    },
                ],
                clueMode: 'swipe',
                cluePages: [
                    {
                      suggest: "Very often sculptors made some small plaster models of the sculptures and took photos of them, so it would be great to find if there are any photo of the Goldsmith's Gold models.There are lots of photos of Kerdić`s art works in the Fine Arts Archives Photograph collection so Vesna browses through the photographs of variations of plaster sketches.",
                    },
                    {
                      suggest: 'There are several different models, all positioned in a niche: one of them is naked, the other one has a long braid, they both hold a key and small opened box. When Vesna compares the monument to those sketches, she realizes that the box in Dora’s hand is in fact closed.',
                    },
                  ],
            },
            success: {
                text: 'She finds the plaster model of the realized sculpture, with closed box in her left hand, placed in the niche, similar to the one at the Stone Gate.',
                image: '',
            }
        },
        {
            id: '23',
            title: 'The face',
            data: {
                suggest: "The initial question was whether Kerdić has had a model that posed for the Goldsmith's Gold sculpture? Vesna now focuses on the different face shapes of the proposed sculptures, trying to find the right face, preferably a face close-up photo. The first face is square shaped, so it couldn’t be the realized one. The other one is oval, like Dora’s at the Gate, but she has a braid. She has to look for oval shaped face with hair in a bun.",
                mode: 'swipe',
                images: [
                    {
                        source : '',
                        isRight: false
                    },
                    {
                        source : '',
                        isRight: true
                    },
                    {
                        source : '',
                        isRight: false
                    }
                ]
            },
            success: {
                text: 'Finally, the right photo! The face is oval, hair is in a bun, now Vesna has close-up of Dora’s face, useful for possible comparison.',
                image: '',
            }
        },
        {
            id: '24',
            title: 'The article',
            data: {
                suggest: "Now, it would be good to take a look at the documentation if there are some interesting articles that could give a clue. In Kerdić's documentation, Vesna finds an article about a dance party, which took place in Croatian Journalists' Association building in Zagreb, in 1929. She wonders why Kerdić would preserve this type of article, given that other articles in the documentation are mostly related to his biography and works.",
                clueWidth: 60,
                clueHeight: 60,
                clueOffsetTop: 180,
                clueOffsetRight: 100,
                mode: 'vertical',
                imgWidth: 843,
                imgHeight: 1146,
                clueMode:'swipe',
                cluePages: [
                    {
                      suggest: "Now, it would be good to take a look at the documentation if there are some interesting articles that could give a clue. In Kerdić's documentation, Vesna finds an article about a dance party, which took place in Croatian Journalists' Association building in Zagreb, in 1929.",
                    },
                    {
                      suggest: 'She wonders why Kerdić would preserve this type of article, given that other articles in the documentation are mostly related to his biography and works. Under the one of the published photos of elegant young ladies at the party, she finds the name of Branka Kerdić, whose flawless oval face resembles the face of Dora Krupićeva sculpture.',
                    },
                  ],
            },
            success: {
                text: "She was the daughter of sculptor's brother Milivoj Kerdić, director of the Croatian Business Association.​",
                image: '',
            }
        }
    ],
    end : {
        text: 'The young woman, a member of the Zagreb high society, known for her beauty, posed to her uncle as a model for the sculpture of literary character Dora Krupićeva.',
        image: '',
        videoUrl: 'https://luigidibiasi.it/CREARCH/hr.mp4',
        videoID: '7bc6PYbQ8QU'
    } 
};