import Vue from 'vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import VueGeolocation from 'vue-browser-geolocation';
import Autocomplete from '@trevoreyre/autocomplete-vue'
import '@trevoreyre/autocomplete-vue/dist/style.css'

Vue.use(Autocomplete)

import './assets/css/main.scss'

  
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueGeolocation);
  
new Vue({
  el: '#app',
  router,
  i18n,
  render: h => h(App)
})