<template>
  <div class="story-list">
    <div class="row">
      <div class="col d-flex flex-wrap justify-content-center">
        <div v-for="(item, index) in story.clues" :key="index" @click="swapView('StoryGame', index)" :class="(index == 0 || isCompleted(index-1)) ? '' : 'disabled'" class="story-list-item m-3 d-flex align-items-center">
          <div v-if="isCompleted(index)" :class="story.slug + ' list-item-marker'"></div>
          <span class="pb-1">{{ item.title }}</span>
        </div>

        <!-- COMPLETED BUTTONS -->
        <div v-if="isStoryCompleted" class="w-100 d-flex justify-content-between">
          <b-button @click="reset" class="btn btn-light play btn-shadow d-block m-auto">
            <img src="../../assets/images/layout/reset.png" alt="Immagine di completamento capitolo">
          </b-button>

          <b-button @click="showModal" class="btn btn-light play btn-shadow d-block m-auto">
            <img src="../../assets/images/layout/play.png" alt="Immagine di completamento capitolo">
          </b-button>
        </div>
      </div>
    </div>

    <!-- VIDEO MODAL -->
    <b-modal ref="video-modal" size="xl" centered hide-footer hide-header>
      <div class="video-modal-body d-block text-center">
        <iframe width="100%" height="615" :src="'https://www.youtube.com/embed/' + story.end.videoID" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { getUserData } from "../../utils/utils"

export default {
  name: 'StoryList',

  props: ['story-id', 'swapView', 'story'],

  data: function() {
    return {
      data: null,
      isStoryCompleted: false
    }
  },

  mounted() {
    this.data = getUserData();
    this.checkStatusStory()
  },

  methods: {
    isCompleted(chapterIndex) {
      if (Number(this.data[this.$route.params.id-1][chapterIndex]))
        return true;
      else
        return false;
    },

    checkStatusStory() {
      this.isStoryCompleted = this.data[this.$route.params.id-1].every(c => Number(c) === 1);
    },

    showModal() {
      this.$refs['video-modal'].show()
    },

    hideModal() {
      this.$refs['video-modal'].hide()
    },

    reset() {
      this.data.splice(this.$route.params.id-1, 1, [0, 0, 0, 0, 0]);
      localStorage.setItem("data", JSON.stringify(this.data));
    },
  }
}
</script>