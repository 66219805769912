<template>
  <div class="story-intro">
    
    <div v-if="currentView == 1">
      <StorySlider v-if="story.abstract.mode == 'swipe'" :story="story" :swap-view="swapView" :slides="story.abstract.pages" type="abstract" @next-view="currentView++"/>

      <div v-else class="p-3 text-center">
        <img :src="require('../../assets/images/' + story.slug + '/info/intro.png')" class="mb-3" alt="Immagine di intro">

        <p class="mb-3">{{ story.abstract.text }}</p>

        <b-button variant="outline-primary" class="d-block m-auto" @click="nextView()">
          <img src="../../assets/images/layout/next.png" alt="Next">
        </b-button>
      </div>
    </div>

    <div v-else>
      <StorySlider :story="story" :swap-view="swapView" :slides="story.intro" type="intro"/>
    </div>

    <!-- Slide info
    <b-carousel
      v-if="currentView == 1"
      id="carousel-info"
      ref="storyAbstractCarousel"
      interval="5000"
      indicators
      img-width="1024"
      img-height="480"
    >
      
      <b-carousel-slide v-for="(item, index) in story.abstract.pages" :key="index">
        <template v-slot:img>
          <div class="story-slide d-flex">
            <div class="slide-img">
              
            </div>

            <div class="slide-body ml-3">
              <p>
                {{ item.text }}
              </p>

              <b-button v-if="index === (story.intro.length-1)" variant="outline-primary" @click="swapView('StoryList')">Next</b-button>
            </div>
          </div>
        </template>
      </b-carousel-slide>
    </b-carousel>
    
    <b-carousel
      id="carousel-1"
      ref="storyCarousel"
      interval="5000"
      indicators
      img-width="1024"
      img-height="480"
    >
      
      <b-carousel-slide v-for="(item, index) in story.intro" :key="index">
        <template v-slot:img>
          <div class="story-slide d-flex">
            <div class="slide-img">
              <img :src="require('../../assets/images/' + story.slug + '/info/' + (index + 1) + '.png')" >
            </div>

            <div class="slide-body ml-3">
              <p>
                {{ item.text }}
              </p>

              <b-button v-if="index === (story.intro.length-1)" variant="outline-primary" @click="swapView('StoryList')">Next</b-button>
            </div>
          </div>
        </template>
      </b-carousel-slide>
    </b-carousel>
    
    <div class="carousel-navigation d-flex justify-content-center mb-2">
      <b-button variant="circle-primary" size="xl" class="btn-circle btn-light btn-shadow mx-3" @click="prev()">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.58 25.29"><g id="Livello_2" data-name="Livello 2"><g id="Livello_1-2" data-name="Livello 1"><path d="M.81,14.15,16.75,25a1.81,1.81,0,0,0,2.83-1.5V19.13l-7.33-5a1.8,1.8,0,0,1,0-3l7.33-5V1.83A1.81,1.81,0,0,0,16.75.32L.81,11.14A1.8,1.8,0,0,0,.81,14.15Z" fill="#714013"/></g></g></svg>
      </b-button>

      <b-button variant="circle-primary" size="xl" class="btn-circle btn-light btn-shadow mx-3" @click="next()">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.58 25.29"><g id="Livello_2" data-name="Livello 2"><g id="Livello_1-2" data-name="Livello 1"><path d="M18.78,14.15,2.83,25A1.81,1.81,0,0,1,0,23.47V19.13l7.34-5a1.81,1.81,0,0,0,0-3L0,6.16V1.83A1.81,1.81,0,0,1,2.83.32l16,10.82A1.81,1.81,0,0,1,18.78,14.15Z" style="fill:#714013"/></g></g></svg>
      </b-button>
    </div>
    -->
  </div>
</template>

<script>
import { getUserData } from "../../utils/utils";
import StorySlider from '@/components/element/StorySlider.vue';

export default {
  name: 'StoryInfo',

  props: ['story-id', 'swapView', 'story'],

  components: {
    StorySlider
  },

  data: function() {
    return {
      currentView: 1,
    }
  },

  mounted: function() {
    var data = getUserData();
    if (Number(data[this.$route.params.id-1][0]))
      this.currentView = 2;
    else
      this.currentView = 1;
  },

  methods: {
    nextView() {
      this.currentView++;
    },

    prev() {
      if(this.currentView == 1)
        this.$refs.storyAbstractCarousel.prev()
      else
        this.$refs.storyCarousel.prev()
    },
    next() {
      if(this.currentView == 1)
        this.$refs.storyAbstractCarousel.next()
      else
        this.$refs.storyCarousel.next()
    }
  }
}
</script>