<template>
  <div>
    <!-- STORY VIEW: schermata di gioco se non completata -->
    <div v-if="!isCompleted" class="container mt-4 mb-5 story-game">
      <!-- Versione normale -->
      <div v-if="chapter.data.mode == 'horizontal' || chapter.data.mode == 'vertical'" class="row">
        <div class="col">
          <figure id="story-game-image">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" :viewBox="'0 0 ' + chapter.data.imgWidth + ' ' + chapter.data.imgHeight">
              <image :width="chapter.data.imgWidth" :height="chapter.data.imgHeight" @click="wrong" :xlink:href="require('../../assets/images/' + story.slug + '/game/' + chapter.id + getImgExtension())">
              </image>

              <a xlink:href="#" @click.prevent="success">
                <rect :style="wrongChoice >= 5 ? 'fill:transparent;stroke:red;stroke-width:10;opacity:0.5': ''" opacity="0" rx="50" ry="50" :width="chapter.data.clueWidth" :height="chapter.data.clueHeight" :x="chapter.data.clueOffsetTop" :y="chapter.data.clueOffsetRight"></rect>
              </a>
            </svg>
          </figure>
        </div>

        <div class="col">
          <div class="story-game-header mb-3 d-flex align-items-center">
            <b-button variant="primary" @click="swapView('StoryList')">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.58 25.29"><g id="Livello_2" data-name="Livello 2"><g id="Livello_1-2" data-name="Livello 1"><path d="M.81,14.15,16.75,25a1.81,1.81,0,0,0,2.83-1.5V19.13l-7.33-5a1.8,1.8,0,0,1,0-3l7.33-5V1.83A1.81,1.81,0,0,0,16.75.32L.81,11.14A1.8,1.8,0,0,0,.81,14.15Z" fill="#714013"/></g></g></svg>
            </b-button>

            <h2 class="title mb-0 ml-2">{{ chapter.title }}</h2>
          </div>

          <div class="story-game-body">
            <div v-if="chapter.data.clueMode == 'swipe'">
              <b-carousel
                id="clue-swipe"
                ref="clue-swipe"
                :interval="5000"
                img-width="1024"
                img-height="480"
              >
                <!-- Single slide -->
                <b-carousel-slide v-for="(item, index) in chapter.data.cluePages" :key="index">
                  <template v-slot:img>
                    <div class="story-slide d-flex">
                      <div class="slide-body">
                        <p>
                          {{ item.suggest }}
                        </p>
                      </div>
                    </div>
                  </template>
                </b-carousel-slide>
              </b-carousel>
            </div>
            <div v-else>
              <p>{{ chapter.data.suggest }}</p>
            </div>
            <b-button variant="outline-primary" class="ml-auto" @click="zoom">Zoom</b-button>
          </div>
        </div>
      </div>

      <!-- Versione Swipe -->
      <div v-else class="row story-game-swipe">
        <b-carousel
          id="carousel-1"
          ref="storyCarousel"
          :interval="5000"
          img-width="1024"
          img-height="480"
          class="mx-auto"
        >
          <!-- Slide image -->
          <b-carousel-slide v-for="(image, index) in chapter.data.images" :key="index">
            <template v-slot:img>
              <div class="story-game-swipe d-flex text-center">
                <div @click="image.isRight? success() : wrong()" class="slide-img">
                  <img :src="require('../../assets/images/' + story.slug + '/game/' + chapter.id + '-' + (index + 1) + getImgExtension())">
                </div>
              </div>
            </template>
          </b-carousel-slide>
        </b-carousel>

        <div class="carousel-navigation d-flex justify-content-between mb-2">
          <b-button variant="circle-primary" size="xl" class="btn-circle btn-light btn-shadow mx-3" @click="prev()">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.58 25.29"><g id="Livello_2" data-name="Livello 2"><g id="Livello_1-2" data-name="Livello 1"><path d="M.81,14.15,16.75,25a1.81,1.81,0,0,0,2.83-1.5V19.13l-7.33-5a1.8,1.8,0,0,1,0-3l7.33-5V1.83A1.81,1.81,0,0,0,16.75.32L.81,11.14A1.8,1.8,0,0,0,.81,14.15Z" fill="#714013"/></g></g></svg>
          </b-button>

          <b-button variant="circle-primary" size="xl" class="btn-circle btn-light btn-shadow mx-3" @click="next()">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.58 25.29"><g id="Livello_2" data-name="Livello 2"><g id="Livello_1-2" data-name="Livello 1"><path d="M18.78,14.15,2.83,25A1.81,1.81,0,0,1,0,23.47V19.13l7.34-5a1.81,1.81,0,0,0,0-3L0,6.16V1.83A1.81,1.81,0,0,1,2.83.32l16,10.82A1.81,1.81,0,0,1,18.78,14.15Z" style="fill:#714013"/></g></g></svg>
          </b-button>
        </div>

        <div class="story-game-header mt-3 mb-2 w-100 justify-content-center d-flex align-items-center">
          <b-button variant="primary" @click="swapView('StoryList')">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.58 25.29"><g id="Livello_2" data-name="Livello 2"><g id="Livello_1-2" data-name="Livello 1"><path d="M.81,14.15,16.75,25a1.81,1.81,0,0,0,2.83-1.5V19.13l-7.33-5a1.8,1.8,0,0,1,0-3l7.33-5V1.83A1.81,1.81,0,0,0,16.75.32L.81,11.14A1.8,1.8,0,0,0,.81,14.15Z" fill="#714013"/></g></g></svg>
          </b-button>

          <h2 class="title mb-0 ml-2">{{ chapter.title }}</h2>
        </div>

        <div v-if="chapter.data.clueMode == 'swipe'" class="suggest">
          <b-carousel
            id="clue-swipe"
            ref="clue-swipe"
            :interval="5000"
            img-width="1024"
            img-height="480"
          >
            <!-- Single slide -->
            <b-carousel-slide v-for="(item, index) in chapter.data.cluePages" :key="index">
              <template v-slot:img>
                <div class="story-slide d-flex ">
                  <div class="slide-body mx-auto">
                    <p>
                      {{ item.suggest }}
                    </p>
                  </div>
                </div>
              </template>
            </b-carousel-slide>
          </b-carousel>
        </div>
        <div v-else class="suggest">
          <p class="mx-auto mt-3">{{ chapter.data.suggest }}</p>
        </div>
      </div>
    </div>

    <!-- SUCCESS VIEW -->
    <div v-else class="container story-game success mt-4 text-center">
      <div class="story-game-header mb-3 d-flex justify-content-center align-items-center">
        <b-button variant="primary" @click="swapView('StoryList')">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.58 25.29"><g id="Livello_2" data-name="Livello 2"><g id="Livello_1-2" data-name="Livello 1"><path d="M.81,14.15,16.75,25a1.81,1.81,0,0,0,2.83-1.5V19.13l-7.33-5a1.8,1.8,0,0,1,0-3l7.33-5V1.83A1.81,1.81,0,0,0,16.75.32L.81,11.14A1.8,1.8,0,0,0,.81,14.15Z" fill="#714013"/></g></g></svg>
        </b-button>

        <h3 class="title mb-0 ml-2">{{ chapter.title }}</h3>
      </div>
              
      <p>{{ chapter.success.text }}</p>

      <img class="success-img mb-3" :src="require('../../assets/images/' + story.slug + '/success/' + chapter.id + '.png')" alt="Immagine di completamento capitolo">

      <b-button variant="primary" class="btn-light btn-shadow d-block m-auto" @click="(chapter.id - (storyID*10)) == 4 ? swapView('StoryList') : nextChapter()">
        <img src="../../assets/images/layout/next.png" alt="Immagine di completamento capitolo">
      </b-button>
    </div>

    <!-- WRONG MODAL -->
    <b-modal ref="wrong-modal" centered hide-footer hide-header>
      <div class="wrong-modal-body d-block text-center">
         <img src="../../assets/images/layout/modal_unsuccess.png" alt="Immagine di completamento capitolo">
      </div>
    </b-modal>

    <!-- ZOOM MODAL -->
    <b-modal v-if="chapter.data.mode !== 'swipe'" ref="zoom-modal" size="xl" hide-footer hide-header>
      <div class="d-block text-center">
        <figure id="story-game-image">
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" :viewBox="'0 0 ' + chapter.data.imgWidth + ' ' + chapter.data.imgHeight">
            <image :width="chapter.data.imgWidth" :height="chapter.data.imgHeight" @click="wrong" :xlink:href="require('../../assets/images/' + story.slug + '/game/' + chapter.id + getImgExtension())">
            </image>

            <a xlink:href="#" @click.prevent="success(true)">
              <rect :style="wrongChoice == 5 ? 'fill:transparent;stroke:red;stroke-width:5;opacity:0.5': ''" opacity="0" :width="chapter.data.clueWidth" :height="chapter.data.clueHeight" :x="chapter.data.clueOffsetTop" :y="chapter.data.clueOffsetRight"></rect>
            </a>
          </svg>
        </figure>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { getUserData } from "../../utils/utils"

export default {
  name: 'StoryGame',

  props: ['story-id', 'swapView', 'story'],

  data: function() {
    return {
      storyID: this.$route.params.id,
      chapter: this.$parent.chapter,
      isCompleted: false,
      wrongChoice: 0,
      data: []
    }
  },

  mounted: function() {
    this.data = getUserData();
    var coefficient = this.$route.params.id*10;
    if(this.data[this.$route.params.id-1][Number(this.chapter.id) - coefficient])
      this.isCompleted = true;
  },

  methods: {
    success(isZoom) {
      if(isZoom)
        this.hideModal('zoom-modal');
        
      this.isCompleted = true;

      this.data = getUserData();
      var coefficient = this.$route.params.id*10;

      this.data[this.$route.params.id-1][Number(this.chapter.id) - coefficient] = 1;

      localStorage.setItem("data", JSON.stringify(this.data));
    },

    wrong() {
      this.showModal('wrong-modal');
      this.wrongChoice++;
    },

    zoom() {
      this.showModal('zoom-modal');
    },

    nextChapter() {
      var chapterID = Number(this.chapter.id);

      this.isCompleted = false;
      this.wrongChoice = 0;
      this.chapter = this.story.clues.find(chapter => chapter.id == (chapterID + 1))
    },

    showModal(modal) {
      this.$refs[modal].show()
    },

    hideModal(modal) {
      this.$refs[modal].hide()
    },

    prev() {
      this.$refs.storyCarousel.prev()
    },

    next() {
      this.$refs.storyCarousel.next()
    },

    getImgExtension() {
      let extension = this.$route.params.id == 2 ? ".png" : ".jpg";
      console.log(extension);
      return this.$route.params.id == 2 ? ".png" : ".jpg";
    }
  }
  
}
</script>