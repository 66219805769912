import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Stories from '../views/Stories.vue';
//import StoryGame from '../views/StoryGame.vue';
import Partner from '../views/Partner.vue';
import About from '../views/About.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/stories/:id?',
    name: 'stories',
    component: Stories,
  },
  /*{
    path: '/stories/storygame/:idChapter',
    name: 'storygame',
    component: StoryGame,
  },*/
  {
    path: '/partner',
    name: 'Partner',
    component: Partner
  },
  {
    path: '/about',
    name: 'About',
    component: About
  }
]

const router = new VueRouter({
  routes,
  mode: "history"
})

export default router
