<template>
  <div>
    <b-carousel
      :id="type + 'Carousel'"
      :ref="type"
      :interval="5000"
      indicators
      img-width="1024"
      img-height="480"
      @sliding-start="onSlideStart"
    >
      <!-- Single slide -->
      <b-carousel-slide v-for="(item, index) in slides" :key="index">
        <template v-slot:img>
          <div class="story-slide d-flex">
            <div class="slide-img">
              <img :src="require('../../assets/images/' + story.slug + '/info/' + (type == 'abstract' ? 'intro-' : '') + (index + 1) + '.png')" >
            </div>

            <div class="slide-body ml-5">
              <h4 v-if="item.title" class="title mb-3">{{ item.title }}</h4>

              <p>
                {{ item.text }}
              </p>

              <b-button v-if="index === (slides.length-1)" variant="outline-primary" @click="type == 'abstract'? nextView() : swapView('StoryList')">
                <img src="../../assets/images/layout/next.png" alt="Next">
              </b-button>
            </div>
          </div>
        </template>
      </b-carousel-slide>
    </b-carousel>
    
    <div class="carousel-navigation d-flex justify-content-center mb-2">
      <b-button variant="circle-primary" size="xl" class="btn-circle btn-light btn-shadow mx-3" @click="prev()">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.58 25.29"><g id="Livello_2" data-name="Livello 2"><g id="Livello_1-2" data-name="Livello 1"><path d="M.81,14.15,16.75,25a1.81,1.81,0,0,0,2.83-1.5V19.13l-7.33-5a1.8,1.8,0,0,1,0-3l7.33-5V1.83A1.81,1.81,0,0,0,16.75.32L.81,11.14A1.8,1.8,0,0,0,.81,14.15Z" fill="#714013"/></g></g></svg>
      </b-button>

      <b-button v-if="isEnabled" variant="circle-primary" size="xl" class="btn-circle btn-light btn-shadow mx-3" @click="next()">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.58 25.29"><g id="Livello_2" data-name="Livello 2"><g id="Livello_1-2" data-name="Livello 1"><path d="M18.78,14.15,2.83,25A1.81,1.81,0,0,1,0,23.47V19.13l7.34-5a1.81,1.81,0,0,0,0-3L0,6.16V1.83A1.81,1.81,0,0,1,2.83.32l16,10.82A1.81,1.81,0,0,1,18.78,14.15Z" style="fill:#714013"/></g></g></svg>
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StorySlides',

  props: ['story', 'swapView', 'slides', 'type'],

  data: function() {
    return {
      isEnabled: true
    }
  },

  computed: {
    slideIndex() {
      return this.$refs[this.type].index;
    }
  },

  methods: {
    prev() {
        this.$refs[this.type].prev();
    },
    
    next() {
        this.$refs[this.type].next();
    },

    onSlideStart() {
      if (this.slideIndex < (this.slides.length-1)) {
        this.isEnabled = true;
      } else {
        this.isEnabled = false;
      }
    },

    nextView() {
      this.$emit("next-view");
      this.$refs[this.type].setSlide(2);
    }
  }
}
</script>