import Vue from 'vue';
import VueI18n from 'vue-i18n'
import { getBrowserLocale } from './utils/utils'
import en from './locales/en.json';
import it from './locales/it.json';

Vue.use(VueI18n);

const currentLocale = getBrowserLocale({ countryCodeOnly: true });

const i18n = new VueI18n({
  locale: currentLocale != 'it' ? process.env.VUE_APP_I18N_LOCALE || 'en' : currentLocale,
  fallbackLocale: currentLocale != 'it' ? process.env.VUE_APP_I18N_LOCALE || 'en' : currentLocale,
  messages:{
    it:it,
    en:en
  }, // set locale messages
})

export default i18n;