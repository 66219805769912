<template>
  <div class="container">
    <div class="credits main-box mx-auto text-center">
      <img class="mt-3" src="../assets/images/layout/credits.png" alt="Marchi "/>
      <a class="mt-5 d-block text-" href="http://incoerenze.it/"><u><b>incoerenze.it</b></u></a>
      <img class="mt-5" src="../assets/images/layout/social_credit.png" alt="Social"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About'
}
</script>


