export const CARAVAGGIO_STORY = {
    id: '1',
    title: 'In the footsteps of Caravaggio',
    slug: 'caravaggio',
    btn: '',
    footer: {
        closed: '',
        open: ''
    },
    abstract: {
        text: "The Fondazione Banco di Napoli has its own innovative, awareness-raising museum project founded in 2016 and located within the Historical Archives of the Banco di Napoli in the heart of the city. The Fondazione's goal is to promote and protect the cultural heritage found in the archival documents in his custody. This is achieved through traditional and multimedia storytelling in order to encourage visitors to appreciate the Archives and to create a sense of participation and ownership in the local communities. The documents used in this app belong to the Historical Archives of Banco di Napoli, the largest economic historical archive in Europe, and the materials used in this app belong to the historical archive of Banco di Napoli and concern the passage of the great painter, Caravaggio, in the city between 1606 and 1607.",
        image: '',
        mode: 'single-page'
    },
    intro: [
        {
            title: 'Naples, May 1607',
            text: 'A rich Croatian merchant, Nicolò Radolovich, commissioned a painting from Michelangelo Merisi known as Caravaggio, the most famous painter of the time. Caravaggio is a problematic and expensive painter, Radolovich pays a deposit of 200 silver ducats through the Neapolitan public banking system to secure his work.',
            image: '',
        },
        {
            text: "In the accounting registers of the banks the reason for the payment is noted, in detail and by sign, with a description of the picture. But it's just a down payment. Months pass, the painting is not seen and Caravaggio has disappeared from the city.",
            image: '',
        },
        {
            text: "The rich Radolovich puts his agent, Gioacchino Donnino, on the painter's trail. To find the defaulting artist, Donnino will look for his traces in the last place where Caravaggio was seen: Neapolitan banks.",
            image: '',
        },
        {
            text: "Gioacchino Donnino is a Neapolitan accountant. He is a trusted man of the rich Croatian merchant Nicolo Radolovich. Thanks to his accounting knowledge he set out on the trail of the painter Michelangelo Merisi, known as Caravaggio. Radolovich's hope is to recover the large advance given to Caravaggio for a painting.",
            image: '',
        },
    ],
    btnClueCompleted: '',
    clues:[
        {
            id: '10',
            title: 'Caravaggio Account',
            data: {
                suggest: 'The banks keep all their accounting records in huge archives. There are customer registers, pandettes, books of accounts, libri maggiori, and journals, with all the reasons for payment. It seems to be in a paper labyrint. Gioacchino is looking for a big book with the date of 1606 written on it. Help him find it among the shelves.​',
                clueWidth: 120,
                clueHeight: 70,
                clueOffsetTop: 750,
                clueOffsetRight: 890,
                mode: 'vertical',
                imgWidth: 1200,
                imgHeight: 1599
            },
            success: {
                text: "There he is! Here are all the operations of Banco di Sant’Eligio customers for 1606. But the page with Caravaggio's account has been torn. We cannot know his last operation before he left the bank. Probably Caravaggio is trying to escape taking Radolovich's money with him.​",
                image: '',
            }
        },
        {
            id: '11',
            title: 'The shelves',
            data: {
                suggest: 'The last place where Caravaggio was seen is the cashier of the Banco di Sant’Eligio. He withdrew all his ducats and disappeared. It may be a good idea to see if there is an account number in the pandetta, the customer name index. From our information it is number 1251. Help Gioacchino find him on the pandetta page.​',
                clueWidth: 60,
                clueHeight: 60,
                clueOffsetTop: 844,
                clueOffsetRight: 610,
                mode: 'vertical',
                imgWidth: 1200,
                imgHeight: 1599
            },
            success: {
                text: "Found it! Now we can look for Michelangelo Caravaggio's account on the major book, the book where there are all the transactions of all the customers of the counter.​"
            }
        },
        {
            id: '12',
            title: 'The portrait',
            data: {
                suggest: "No one can have access to the bank's archives without the authorization of its governors. It seems that one of the bank employees made an agreement with Caravaggio and ripped off the page. One of the cashier aides says he saw Caravaggio arguing with a cashier one day. The assistant is fond of drawing and made their portraits. From the boy's description the cashier should be a man with no hair and a pointed beard. Help Gioacchino find him on this page.​",
                clueWidth: 80,
                clueHeight: 80,
                clueOffsetTop: 650,
                clueOffsetRight: 210,
                mode: 'vertical',
                imgWidth: 931,
                imgHeight: 914
            },
            success: {
                text: 'From what the governors of the bank say, this should be the portrait of Andrea Chiaramonte, cashier of the bank of Sant’Eligio. All that remains is to question him.​',
            }
        },
        {
            id: '13',
            title: 'Silver ducats',
            data: {
                suggest: 'Chiaramonte confessed to having received a tip from Caravaggio to cut off his account from the major book. To avoid troubles he confided to Gioacchino that Caravaggio, before going, had asked him for information on a certain cashier of the bank of the Holy Spirit. In the newspaper of 11 May 1607 there is a payment by Tommaso De Franchis to Caravaggio. Gioacchino must find the amount. It should be 150 ducats: help him locate it within the reason for payment.',
                clueWidth: 60,
                clueHeight: 60,
                clueOffsetTop: 690,
                clueOffsetRight: 110,
                mode: 'horizontal',
                imgWidth: 1172,
                imgHeight: 308
            },
            success: {
                text: "'To Tomaso de Franchis 100 ducats and for him to Michelangelo Caravaccio said he pays them to us for the fulfillment of two hundred and fifty ducats, awaiting the other 150 ducats he received them in cash and are on account of a ... who must deliver them'. The reason for payment is erased in the description. Strange, but now we know that Caravaggio had at least 100 ducats with him to try to escape the city.​​",
                image: '',
            }
        },
        {
            id: '14',
            title: 'The ships',
            data: {
                suggest: "It seems that Caravaggio has secured a place on a ship bound for Malta. A port customs collector claims to have seen him board a ship, but he doesn't quite remember which one. Fortunately, he made a small sketch of the various ships that sailed that day. One of them is the one on which the painter seems to have embarked. The ship bound for Malta had a ten-petalled flower on its flag. Help Gioacchino to find her.",
                clueWidth: 70,
                clueHeight: 70,
                clueOffsetTop: 170,
                clueOffsetRight: 380,
                mode: 'horizontal',
                imgWidth: 1166,
                imgHeight: 653
            },
            success: {
                text: 'Yes, the ship looks just like this. As expected, Caravaggio left the city without completing the work! Or maybe he finished it and took it with him. Or worse for Radolovich he found a way to sell it to someone else.​',
                image: '',
            }
        }
    ],
    end : {
        text: "There is no way to see the result of the painter's work, the rich Croatian merchant can only imagine how the masterpiece he had asked for could have been...",
        videoID: '6pkbpQrn9tI'
    }
};