/**
 * Get locale from browser location
 * 
 * @param {Array} options options
 * @returns 
 */
export function getBrowserLocale(options = {}) {
    const defaultOptions = { countryCodeOnly: false }
  
    const opt = { ...defaultOptions, ...options }
  
    const navigatorLocale =
      navigator.languages !== undefined
        ? navigator.languages[0]
        : navigator.language
  
    if (!navigatorLocale) {
      return undefined
    }
  
    const trimmedLocale = opt.countryCodeOnly
      ? navigatorLocale.trim().split(/-|_/)[0]
      : navigatorLocale.trim()
  
    return trimmedLocale
}

export function getUserData() {
  var data = JSON.parse(localStorage.getItem("data"));

  if(data === null) {
    data = [
      [0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0]
    ];

    localStorage.setItem("data", JSON.stringify(data));
  }

  return data;
}
