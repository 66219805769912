export const MUSIC_STORY = {
    id: '3',
    title: 'The stolen music',
    slug: 'music',
    footer: {
      closed: '',
      open: '',
    },
    abstract: {
      pages: [
        {
          title: 'University of the Peloponnese - School of Arts: The Theatre Studies Department',
          text: 'The Department of Theatre Studies of the School of Fine Arts, of the University of the Peloponnese is located at the historical city of Nafplion, in Argolis. The Department offers BA and MA programs in Theatre focusing both on theory and on applied performance (drama studies, directing, scenography, choreography, anthropology of theatre, cinema, drama in education etc.).The Department of Theatre Studies hosts numerous research projects and collaborates with a number of international academic institutes and research centers.',
        },
        {
          title: 'Friends of Music Society - Music Library of Greece "Lilian Voudouri"',
          text: 'Unique among its kind in Greece and freely accessible to the public, the Library has been organised and continues to operate with the aim of addressing the needs of a wide range of users. The Library uses all modern information technologies which have been applied to the field of its activities. The  Greek Music Archive was created in order to fulfill the need for an official body specializing in the collection, preservation, and study of Greek music. The materials used in this app belong to the Greek Music Archive and they are part of the personal archives of Mikis Theodorakis, Nikos Zachariou and Nikos Skalkottas.',
        },
      ],
      mode: 'swipe',
    },
    intro: [
      {
        title: 'Megaron - Music Library & Archive, May 2021',
        text: 'A burglary has taken place in the Greek Music Archive. The museum guard receives a threatening note warning him that if he calls the police, some of the most valuable items of the Archive will be reduced to ashes.',
      },
      {
        text: '“What is missing is something that is worth stealing… if you call the police these items will turn into ashes! Contact the Archivist. She knows exactly what to do!”',
      },
      {
        text: 'The guard hastily summons the Archivist. She thoroughly investigates the Archive and discovers the missing items one by one.',
      },
    ],
    btnClueCompleted: '',
    clues: [
      {
        id: '30',
        title: 'Worth it is',
        data: {
          clueWidth: 370,
          clueHeight: 90,
          clueOffsetTop: 280,
          clueOffsetRight: 150,
          mode: 'vertical',
          imgWidth: 1843,
          imgHeight: 1812,
          clueMode: 'swipe',
          cluePages: [
            {
              suggest:
                "“What is missing is something that is worth stealing...”. The Archivist immediately focuses on the archive of Mikis Theodorakis, the internationally acclaimed Greek composer (29.7.1925 - 2.9.2021).\nThe back story: On the 21st of April 1967, the Colonels' regime took power of Greece in a coup d’état. Mikis Theodorakis was a renowned member of the Left, so very soon his songs and music were banned.The composer himself was arrested and thrown into prison.",
            },
            {
              suggest:
                'During his imprisonment in the prisons of Oropos, he secretly started writing down his creative thoughts and draft music compositions on cheap white paper napkins. Indeed, the first paper napkin is missing. Thank God! A digital copy of it still exists! You can help the Archivist carry on the investigation if you discover the title of the famous Mikis Theodorakis works! It starts with the first letter of the alphabet and it is written in capital letters.',
            },
          ],
        },
        success: {
          image: '',
          mode: 'swipe',
          pages: [
            {
              text: 'Indeed, the title of Mikis Theodorakis symphonic poem mentioned in the stolen item is “ΑΞΙΟΝ ΕΣΤΙ” [Axion Esti], a composition based on the poetry of the Nobel Prize winning Greek poet, Odysseas Elytis (2.11.1911-18.3.1996).',
            },
            {
              text: '“AΞΙΟΝ ΕΣΤΙ” is an ancient Greek phrase meaning “It is fitting”! The word “χορικά” [chorikà], which follows the title of the work, refers to the poetry of the Chorus [Choros], the group which sings the choral parts and dances to music in the ancient Greek drama. The Chorus group of performers dance, sing and speak as a collective voice. Thanks to your help, as well as to the Archivist’s resourcefulness, she can now “unlock” the second stolen item and proceed with her investigation.',
            },
          ],
        },
      },
      {
        id: '31',
        title: 'The 36 greek dances',
        data: {
          clueWidth: 1000,
          clueHeight: 200,
          clueOffsetTop: 1950,
          clueOffsetRight: 1300,
          mode: 'vertical',
          imgWidth: 3553,
          imgHeight: 4738,
          clueMode: 'swipe',
          cluePages: [
            {
              suggest:
                'The original manuscript of the scores of the “36 Greek Dances” by Nikos Skalkottas (21.3.1904-19.9.1949) is missing!\nThe back story: Nikos Skalkottas is considered one of most prominent modernist composers of the 20th century. The “36 Greek Dances” is one of the most emblematic works of Greek art music. The work is a creative and historical enigma, because of its unusual length and its initial composition for orchestra.',
            },
            {
              suggest:
                'It combines traditional (folk) Greek musical features with modernistic style. The music of Skalkottas, however, is not a simple arrangement of traditional folk dances but rather a new version and radical interpretation of them. Here is the digital copy of the first page of the manuscript. Trace the Greek version of the composer’s signature, which is a lead to the next item.',
            },
          ],
        },
        success: {
          text: 'Found it! This is the signature of the 20th century Greek modernist composer Nikos Skalkottas. He dedicated this fascinating work, the “36 Greek Dances”, to the internationally acclaimed Greek orchestra conductor, composer and pianist Dimitris Mitropoulos (18.2.1896-2.11.1960), whom we will encounter right away.',
        },
      },
      {
        id: '32',
        title: 'The Athens Festival of 1958',
        data: {
          suggest:
            'The short version of the evening programme of the 1958 Athens and Epidaurus Festival is missing!\nThe back story: That rare booklet contained information about all the theatrical and musical performances that were produced at the celebrated Odeon of Herodes Atticus (Herodion) during the summer of 1958. One of the most outstanding artistic events, was the concert of the Vienna Philharmonic Orchestra, conducted by Dimitris Mitropoulos. Touch the face of the famous conductor!',
          clueWidth: 240,
          clueHeight: 240,
          clueOffsetTop: 1200,
          clueOffsetRight: 1850,
          mode: 'vertical',
          imgWidth: 1884,
          imgHeight: 2664,
        },
        success: {
          text: 'Dimitris Mitropoulos said: “He, who - just like me - wanders for years and years in the forest of music, and who has been given the privilege of serving as the guardian of the giant trees, has a duty not only to care for these trees, but also to devote time, effort and affection for those who are still struggling to find their place under the sun”. Nikos Skalkottas, another giant tree in the forest of music, wrote in his dedication note to Dimitris Mitropoulos: To our great Greek musician with great friendship and gratitude.\nN. Skalkottas',
        },
      },
      {
        id: '33',
        title: 'Ancient Theatre of Epidaurus',
        data: {
          suggest: '',
          clueWidth: 330,
          clueHeight: 100,
          clueOffsetTop: 820,
          clueOffsetRight: 130,
          mode: 'vertical',
          imgWidth: 1841,
          imgHeight: 1828,
          clueMode: 'swipe',
          cluePages: [
            {
              suggest:
                'Yes! You deserve the title of junior researcher! Our Archivist is certain that you have already recognized the next item.\nThe back story: It is indeed one of the more than 100 paper napkins of Mikis Theodorakis on which the composer used to keep his notes, during his imprisonment by the junta in the Oropos prison, during the year 1969.',
            },
            {
              suggest:
                'In the second line of this unique document, you can see the title of the chapter written in capital letters: “ABOUT A CONTEMPORARY TRAGEDY” On 11 July 1954 the first performance of an ancient Greek tragedy was produced in the Ancient Theatre of Epidaurus, a 4th century B.C open theatre with a capacity of 14.000 seated spectators. ',
            },
            {
              suggest:
                'The play was Euripides’ Hippolytus, staged by the distinguished Greek theatre director Dimitris Rondiris (1899-20.12.1981). The music of the play was composed by Dimitris Mitropoulos. The Epidaurus theatre was flooded with thousands of spectators and its “voice” sounded again after 2500 years of silence! This was the beginning of a long and valuable tradition of revival and representation of Ancient Greek Drama in Greece.',
            },
            {
              suggest:
                'At the same time, an important theoretical discussion was initiated focusing on how ancient Greek plays should be presented in modern times. Should contemporary actors and directors remain strictly loyal to the ancient texts? Are they allowed to propose new interpretations and express their own creative stage language? In the second line of the item try to track down the Greek word for TRAGEDY.',
            },
          ],
        },
        success: {
          text: 'That’s right! You are one step away from solving the mystery!',
          image: '',
        },
      },
      {
        id: '34',
        title: 'The stage costume',
        data: {
          clueWidth: 340,
          clueHeight: 220,
          clueOffsetTop: 644,
          clueOffsetRight: 800,
          mode: 'vertical',
          imgWidth: 1833,
          imgHeight: 2284,
          clueMode: 'swipe',
          cluePages: [
            {
              suggest:
                'It can’t be true! The stage costume of the great Greek opera singer Nikos Zachariou (9.3.1923-24.7.2007) has disappeared!\nThe back story: Nikos Zachariou was an impressive bass singer and famous opera performer of Teatro alla Scala in Milan. He was widely known as Nicola Zaccaria and he has co-starred with many celebrated leading opera singers, such as Maria Callas (2.12.1923-16.9.1977).',
            },
            {
              suggest:
                'Indeed, he was applauded together with Maria Callas when he performed the role of Kreon in the opera Medea by Luigi Cherubini. But where on earth has the authentic stage costume of Kreon gone? Only a picture of it is left! Look at it carefully. Can you find the galloping horses on the upper part of the garment?',
            },
          ],
        },
        success: {
          image: '',
          text: 'That’s right! The costumes for the production of Cherubini’s Medea were created by the charismatic Greek painter, costume and set designer Yannis Tsarouchis (13.1.1910-20.7.1989).',
        },
      },
    ],
    end: {
      text: 'The disappearance of the costume has seriously upset our Archivist. But… Hang on a minute! A small handwritten note has been pinned to the dummy’s back. Please, read it! “The Guardians in ancient Greek tragedy, just as in life, always play second and third roles. This costume will make me a protagonist for the first time!”\nCan you guess who is the mastermind behind the burglary?',
      videoUrl: 'https://luigidibiasi.it/CREARCH/gk.mp4',
      videoID: 'yOkCVgenm7w',
    },
  };