<template>
  <div class="container">
    <div class="partner main-box mx-auto d-flex flex-column">
      <img src="../assets/images/layout/marchi.png" alt="Marchi "/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Partner'
}
</script>